import React from 'react'
import Img from 'gatsby-image'
import Lightbox from 'react-image-lightbox'
import './Gallery.scss'

export default class Gallery extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      photoIndex: 0,
      isOpen: false,
    }
  }

  openLightbox(e) {
    e.preventDefault()
    this.setState({ isOpen: true })
  }

  render() {
    const { photos } = this.props
    const { photoIndex, isOpen } = this.state
    return (
      <div>
        <div className="flex flex-wrap">
          {photos.map((photo, i) => (
            <div className="w-1/4 mb-4" key={i}>
              <a
                href={photo.full.localFile.childImageSharp.fluid.src}
                onClick={e => this.openLightbox(e, i)}
                className="image-zoom"
              >
                <Img fixed={photo.thumbnail.localFile.childImageSharp.fixed} />
              </a>
            </div>
          ))}
        </div>

        {isOpen && (
          <Lightbox
            mainSrc={
              photos[photoIndex].full.localFile.childImageSharp.original.src
            }
            nextSrc={
              photos[(photoIndex + 1) % photos.length].full.localFile
                .childImageSharp.original.src
            }
            prevSrc={
              photos[(photoIndex + photos.length - 1) % photos.length].full
                .localFile.childImageSharp.original.src
            }
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + photos.length - 1) % photos.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % photos.length,
              })
            }
            imageCaption={photos[photoIndex].full.title}
          />
        )}
      </div>
    )
  }
}
