import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import Gallery from '../components/Gallery/Gallery'
import Layout from '../components/Layout'
import SEO from '../components/SEO'

class BlogPostTemplate extends React.Component {
  render() {
    const { content, categories, title, date, image, imageGallery } = this.props

    return (
      <div className="container max-w-4xl">
        <div className="mx-8">
          <h1
            className="underlined"
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <div>
            {image && image.localFile && image.localFile.childImageSharp && (
              <Img
                className="float-right ml-6 mb-6 featured-image"
                fixed={image.localFile.childImageSharp.fixed}
              />
            )}
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: content }}
            />
            {imageGallery && <Gallery photos={imageGallery} />}
          </div>
        </div>
      </div>
    )
  }
}

const BlogPost = ({ data }) => {
  const { wordpressPost: post } = data

  return (
    <Layout>
      <SEO title={`${post.title}`} />
      <BlogPostTemplate
        content={post.content}
        categories={post.categories}
        title={post.title}
        date={post.date}
        image={post.featured_media}
        imageGallery={post.acf.gallery}
      />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  fragment PostFields on wordpress__POST {
    id
    slug
    content
    date(formatString: "DD MMMM YYYY")
    title
  }
  query BlogPostByID($id: String!) {
    wordpressPost(id: { eq: $id }) {
      id
      title
      slug
      content
      featured_media {
        localFile {
          childImageSharp {
            fixed(width: 300, quality: 100) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
      date(formatString: "DD MMMM YYYY")
      categories {
        name
        slug
      }
      acf {
        gallery {
          full: image {
            title
            localFile {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_withWebp
                }
                original {
                  src
                }
              }
            }
          }
          thumbnail: image {
            localFile {
              childImageSharp {
                fixed(width: 150, height: 150) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
